<div class="text-primary md:mt-12 px-5">
  <div class="bg-greyCard rounded-lg mb-4 p-7">
      <div class="w-full">
          <div class="text-base mb-3 lg:text-lg font-medium">Proof of business revenue/solvency <sup>*</sup></div>
          <div>*Previous year's audited or unaudited financial statements (P&L, statement of cash flows)
              confirming the turnover of the business.</div>
      </div>

      <div *ngIf="!getRemoveFilesValue()">
        <mat-accordion class="flex flex-row justify-between mt-3" *ngFor="let selected_file of turnoverFiles; index as i">
            <mat-expansion-panel
            [ngClass]="selected_file.isUploaded ? 'bg-lightGreen' : ''"
            class="w-[85%]" *ngIf="selected_file" disabled [expanded]="selected_file.upload_result">
                <mat-expansion-panel-header [ngClass]="selected_file.upload_result ? 'error-state' : ''">
                    <mat-panel-title>
                        <div class="flex items-center">
                            <mat-icon color="primary">description</mat-icon>
                            <span class="ml-3 text-primary font-medium file-name" matTooltip="{{selected_file.file.name}}">{{selected_file.file.name}}</span>
                            <span class="ml-3 text-black">{{ getFileSize(selected_file.file.size) }}</span>
                        </div>
                    </mat-panel-title>
                    <button *ngIf="selected_file && selected_file?.isUploaded" class="download-icon mt-1 mr-3"
                    (click)="downLoadFile(selected_file.file.id)" matTooltip="Download file">
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </button>
                </mat-expansion-panel-header>
                <div class="py-2 font-bold" [ngClass]="selected_file.isUploaded ? 'text-green' : 'text-red'">
                    {{selected_file.upload_result}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="viewing === 'admin'" class="mt-1 italic text-red blinking-text">
            GDPR compliance: Please ensure to remove this file(s) from download folder
            from your device after downloading/viewing.
        </div>
      </div>
      
      <div *ngIf="getRemoveFilesValue()" class="mt-2">
        <div class="font-bold text-red">File(s) removed for GDPR compliance</div>
      </div>
  </div>
  <div class="bg-greyCard rounded-lg mb-4 p-7">
      <div class="w-full">
          <div class="text-base mb-3 lg:text-lg font-medium">Proof of company registration and ownership/partnership
              status <sup>*</sup>
          </div>
      </div>

      <div class="mb-3">
        @if (country) {
            <div *ngIf="country === 'DE'">
                <div class="font-bold mb-2 underline">Germany</div>
                <div class="mb-2"><span class="font-bold mr-2">1. </span><span class="font-bold mr-1">Registration certificate</span> from the Common Register Portal of the German Federal States
                    (Handels Register) that shows the current share ownership status of a company.
                </div>
            </div>
            
            <div *ngIf="country === 'FR'">
                <div class="font-bold mb-2 underline">France</div>
                <div class="mb-2"><span class="font-bold mr-2">1. </span><span class="font-bold mr-1">K-Bis certificate.</span></div>
            </div>
            
            <div *ngIf="country === 'NL'">
                <div class="font-bold mb-2 underline">Netherlands</div>
                <div class="mb-2"><span class="font-bold mr-2">1. </span><span class="font-bold mr-1">Registration certificate</span> from Dutch Business Register (KVK) shows the current share ownership
                    status of a company.
                </div>
            </div>
        } @else {
            <div>
                <div class="font-bold mb-2 underline">Germany</div>
                <div class="mb-2"><span class="font-bold mr-2">1. </span><span class="font-bold mr-1">Registration certificate</span> from the Common Register Portal of the German Federal States
                    (Handels Register) that shows the current share ownership status of a company.
                </div>
                <div class="mb-2"><span class="font-bold mr-1">2. </span><span class="font-bold mr-1">Articles of Association</span> that indicate the current share ownership status from the Common
                    Register Portal of the German Federal States (Handels Register). OR <span class="font-bold">Ultimate Beneficial Owner</span> document (UBO)
                </div>
            </div>
            
            <div>
                <div class="font-bold mb-2 underline">France</div>
                <div class="mb-2"><span class="font-bold mr-2">1. </span><span class="font-bold mr-1">K-Bis certificate.</span></div>
                <div class="mb-2"><span class="font-bold mr-1">2. </span><span class="font-bold mr-1">Articles of Association</span> that indicate the current share
                     ownership/partnership status. OR <span class="font-bold">Ultimate Beneficial Owner</span> document (UBO)</div>
            </div>
            
            <div>
                <div class="font-bold mb-2 underline">Netherlands</div>
                <div class="mb-2"><span class="font-bold mr-2">1. </span><span class="font-bold mr-1">Registration certificate</span> from Dutch Business Register (KVK) shows the current share ownership
                    status of a company.
                </div>
                <div><span class="font-bold mr-1">2. </span><span class="font-bold mr-1">The Articles of Association</span> that may indicate
                     the ownership status. OR <span class="font-bold">Ultimate Beneficial Owner</span> document (UBO)</div>
            </div>
        }
    
          <div *ngIf="!getRemoveFilesValue()">
            <mat-accordion class="flex flex-row justify-between mt-3" *ngFor="let selected_file of registrationFiles; index as i">
                <mat-expansion-panel
                [ngClass]="selected_file.isUploaded ? 'bg-lightGreen' : ''"
                 class="w-[85%]" *ngIf="selected_file" disabled [expanded]="selected_file.upload_result">
                    <mat-expansion-panel-header [ngClass]="selected_file.upload_result ? 'error-state' : ''">
                        <mat-panel-title>
                            <div class="flex items-center">
                                <mat-icon color="primary">description</mat-icon>
                                <span class="ml-3 text-primary font-medium file-name" matTooltip="{{selected_file.file.name}}">{{selected_file.file.name}}</span>
                                <span class="ml-3 text-black">{{ getFileSize(selected_file.file.size) }}</span>
                            </div>
                        </mat-panel-title>
                        <button *ngIf="selected_file && selected_file?.isUploaded" class="download-icon mt-1 mr-3"
                            (click)="downLoadFile(selected_file.file.id)" matTooltip="Download file">
                            <mat-icon>vertical_align_bottom</mat-icon>
                        </button>
                    </mat-expansion-panel-header>
                    <div class="py-2 font-bold" [ngClass]="selected_file.isUploaded ? 'text-green' : 'text-red'">
                        {{selected_file.upload_result}}
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div *ngIf="viewing === 'admin'" class="mt-1 italic text-red blinking-text">
                GDPR compliance: Please ensure to remove this file(s) from download folder
                from your device after downloading/viewing.
            </div>
          </div>
          <div *ngIf="getRemoveFilesValue()" class="mt-2">
            <div class="font-bold text-red">File(s) removed for GDPR compliance</div>
          </div>
      </div>
      

    @if (country) {
        <div *ngIf="country === 'DE'">
            <div class="mb-2"><span class="font-bold mr-2">2. </span><span class="font-bold mr-1">Articles of Association</span> that indicate the current share ownership status from the Common
                Register Portal of the German Federal States (Handels Register). OR <span class="font-bold">Ultimate Beneficial Owner</span> document (UBO)
            </div>
        </div>
        
        <div *ngIf="country === 'FR'">
            <div class="mb-2"><span class="font-bold mr-2">2. </span><span class="font-bold mr-1">Articles of Association</span> that indicate the current share
                    ownership/partnership status. OR <span class="font-bold">Ultimate Beneficial Owner</span> document (UBO)</div>
        </div>
        
        <div *ngIf="country === 'NL'">
            <div><span class="font-bold mr-2">2. </span><span class="font-bold mr-1">Articles of Association</span> that may indicate the ownership
                    status. OR <span class="font-bold">Ultimate Beneficial Owner</span> document (UBO)</div>
        </div>
    }
    <div *ngIf="!getRemoveFilesValue()">
        <mat-accordion class="flex flex-row justify-between mt-3" *ngFor="let selected_file of ownershipFiles; index as i">
            <mat-expansion-panel
            [ngClass]="selected_file.isUploaded ? 'bg-lightGreen' : ''"
             class="w-[85%]" *ngIf="selected_file" disabled [expanded]="selected_file.upload_result">
                <mat-expansion-panel-header [ngClass]="selected_file.upload_result ? 'error-state' : ''">
                    <mat-panel-title>
                        <div class="flex items-center">
                            <mat-icon color="primary">description</mat-icon>
                            <span class="ml-3 text-primary font-medium file-name" matTooltip="{{selected_file.file.name}}">{{selected_file.file.name}}</span>
                            <span class="ml-3 text-black">{{ getFileSize(selected_file.file.size) }}</span>
                        </div>
                    </mat-panel-title>
                    <button *ngIf="selected_file && selected_file?.isUploaded" class="download-icon mt-1 mr-3"
                        (click)="downLoadFile(selected_file.file.id)" matTooltip="Download file">
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </button>
                </mat-expansion-panel-header>
                <div class="py-2 font-bold" [ngClass]="selected_file.isUploaded ? 'text-green' : 'text-red'">
                    {{selected_file.upload_result}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div *ngIf="viewing === 'admin'" class="mt-1 italic text-red blinking-text">
            GDPR compliance: Please ensure to remove this file(s) from download folder
            from your device after downloading/viewing.
        </div>
      </div>
      <div *ngIf="getRemoveFilesValue()" class="mt-2">
        <div class="font-bold text-red">File(s) removed for GDPR compliance</div>
      </div>

  </div>
  <div class="italic font-medium">*Supply a copy of the applicant’s financial statement for one year preceding the
      year of application or for the
      time that the applicant has been in business if less than one year, plus financial statement of any subsidiaries
      of affiliates of the applicant for the same period. If the applicant is a new business concern, enclose a copy
      of projection of income, or a statement by a certified public accountant, which states that the applicant is a
      viable business concern. All financial statements submitted to ESDP must show applicable date of the information
      given and must be signed and dated by the proprietor, partner or authorized officer unless prepared by an
      independent certified public accountant. All materials will be kept confidential.
  </div>
  <form [formGroup]="uploadForm">
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row justify-start items-center mt-4 gap-3">
                <div class="text-base mb-3 lg:text-lg font-bold">
                    Select membership period: <sup>*</sup>
                </div>
                <mat-form-field class="mt-3 w-[25%]" appearance="outline">
                    <mat-select placeholder="Please select year" formControlName="numberOfSubscriptionYear" panelClass="turnover-select" [disabled]="true">
                        <mat-option value="1">1 year</mat-option>
                        <mat-option value="3">3 years</mat-option>
                    </mat-select>
                    <mat-error *ngIf="uploadForm.get('numberOfSubscriptionYear')?.touched && uploadForm.get('numberOfSubscriptionYear')?.hasError('required')">
                        Year is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="mt-4 text-xl text-green font-semibold">
                <span 
                    [matTooltip]="isExempted ? 'Exempted' : ''" 
                    [ngClass]="{'exempted-fee': isExempted}">
                    Membership Fee: €{{ membershipFee }}
                    <p *ngIf="!isExempted">(incl. VAT 21%)</p>
                </span>
            </div>
        </div>
  </form>
  <div class="flex justify-between mt-6">
      <div class="flex flex-start gap-5">
          <button mat-button matStepperPrevious class="!rounded-[50px] next-button">
              <div class="flex items-center pr-2"><mat-icon>chevron_left</mat-icon>Previous step</div>
          </button>
      </div>
  </div>
</div>
