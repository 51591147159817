<div class="text-primary md:mt-12 px-5">
  <form [formGroup]="businessProfileForm">
    <div formGroupName="businessProfileForm">
      <div class="text-base mb-3 lg:text-lg font-medium"> Please describe your business <sup>*</sup> </div>
      <mat-form-field appearance="outline" class="w-full mb-3" >
        <textarea matInput class="text-area" formControlName="business_description"></textarea>
      </mat-form-field>

      <!--Company founded-->
      <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">When was your company founded? <sup>*</sup></div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="company_foundation_date"
              />
          </mat-form-field>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">Number of employees <sup>*</sup></div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput formControlName="number_of_employees"
              />
          </mat-form-field>
        </div>
      </div>

      <!---gross turnover-->
      <div class="flex justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">Gross turnover for the last year in Euros <sup>*</sup>
          </div>
          <mat-form-field class="w-full" appearance="outline">
            <mat-select formControlName="last_year_gross_turnover_euro">
              @for (turnover of grossTurnovers; track turnover) {
              <mat-option [value]="turnover.value">{{ turnover.key }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base lg:text-lg font-medium">What is your geographical coverage? <sup>*</sup></div>
          <div class="mt-6 text-black">
            <!-- <span class="text-lg" *ngFor="let option of this.businessProfileForm.get('businessProfileForm')?.get('geographical_coverage')?.value; last as isLast">{{ getCountryByCode(option) }}
              <span *ngIf="!isLast"> , </span>
            </span> -->
            <div class="text-lg bg-greyCard py-3 pl-3 rounded-md" *ngFor="let option of this.businessProfileForm.get('businessProfileForm')?.get('geographical_coverage')?.value; last as isLast">{{ getCountryByCode(option) }}
            </div>
          </div>
          
          <!-- @for (geoCoverage of geographicalCoverage; track geoCoverage) {
            {{ geoCoverage.country }}
          } -->
          <!-- <mat-form-field appearance="outline" class="w-full">
            <mat-select formControlName="geographical_coverage" multiple>
              @for (geoCoverage of geographicalCoverage; track geoCoverage) {
              <mat-option [value]="geoCoverage.code" [disabled]="true">{{ geoCoverage.country }}</mat-option>
              }
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>

      <!--industry sectors-->
      <div class="flex justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">Up to 3 industry sectors of your business <sup>*</sup>
          </div>
          <div class="mt-6 text-black">
            <!-- <span class="text-lg" *ngFor="let option of this.businessProfileForm.get('businessProfileForm')?.get('industry_sectors')?.value; last as isLast">{{ getIndustryByKey(option) }}
              <span *ngIf="!isLast"> , </span>
            </span> -->
            <div class="text-lg bg-greyCard py-3 pl-3 rounded-md" *ngFor="let option of this.businessProfileForm.get('businessProfileForm')?.get('industry_sectors')?.value; last as isLast">{{ getIndustryByKey(option) }}
            </div>
          </div>
          <!-- <mat-form-field appearance="outline" class="w-full">
            <mat-select formControlName="industry_sectors" multiple>
              @for (sectors of industrySectors; track sectors) {
              <mat-option [value]="sectors.key" [disabled]="true">{{ sectors.title }}</mat-option>
              }
            </mat-select>
          </mat-form-field> -->
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">Select upto to 5 services you offer<sup>*</sup></div>
          <div class="mt-6 text-black">
            <!-- <span class="text-lg" *ngFor="let option of this.businessProfileForm.get('businessProfileForm')?.get('service_offerings')?.value; last as isLast">{{ getSubIndustryByKey(option) }}
              <span *ngIf="!isLast"> , </span>
            </span> -->
            <div class="text-lg bg-greyCard py-3 pl-3 rounded-md" *ngFor="let option of this.businessProfileForm.get('businessProfileForm')?.get('service_offerings')?.value; last as isLast">{{ getSubIndustryByKey(option) }}
            </div>
          </div>
          <!-- <mat-form-field appearance="outline" class="w-full">
            <mat-select formControlName="service_offerings" multiple>
              @for (service of services; track service) {
              <mat-option [value]="service.key" [disabled]="true">{{ service.title }}</mat-option>
              }
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>

      <!--largest contract-->
      <div class="flex items-center justify-between flex-col lg:flex-row mb-3">
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">The largest contract that your business has delivered
            <sup>*</sup></div>
          <mat-form-field class="w-full" appearance="outline">
            <input matInput
              formControlName="largest_contract"/>
          </mat-form-field>
        </div>
        <div class="w-full lg:w-[48%]">
          <div class="text-base mb-3 lg:text-lg font-medium">Key Clients - Please mention your top three clients
            <sup>*</sup>
          </div>
          <mat-form-field appearance="outline" class="w-full">
            <input matInput formControlName="key_clients" />
          </mat-form-field>
        </div>
      </div>

      <!--elevator pitch-->
      <div class="text-base mb-3 lg:text-lg font-medium">Elevator Pitch <sup>*</sup> </div>
      <mat-form-field appearance="outline" class="w-full mb-3">
        <textarea matInput class="text-area" #pitch formControlName="elevator_pitch"></textarea>
      </mat-form-field>
    </div>

    <div class="flex justify-between">
      <div>
        <button mat-button matStepperPrevious class="!rounded-[50px] next-button">
          <div class="flex items-center pr-2"><mat-icon>chevron_left</mat-icon>Previous step</div>
        </button>
      </div>
      <div class="flex">
        <!-- <button mat-raised-button color="primary" class="mr-4 !rounded-[50px]">Save as draft</button> -->
        <button mat-button matStepperNext class="!rounded-[50px] next-button">
          <div class="flex items-center pl-3">Next step<mat-icon>chevron_right</mat-icon></div>
        </button>
      </div>
    </div>
  </form>
</div>
