import { Component } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrl: './notice.component.css'
})
export class NoticeComponent {
  showHolidayMessage: boolean = false;

  ngOnInit() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startDate = new Date(`${currentYear}-12-16`);
    const endDate = new Date(`${currentYear + 1}-01-06`);

    this.showHolidayMessage = currentDate >= startDate && currentDate <= endDate;
  }
}
