import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DownloadFileService } from '../../../../services/download-file/download-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrl: './view-document.component.css'
})
export class ViewDocumentComponent {
  @Input() uploadForm!: FormGroup;
  @Input() turnoverFiles: any = [];
  @Input() registrationFiles: any = [];
  @Input() ownershipFiles: any = [];
  @Input() viewing: string = '';

  country: any = '';
  membershipFee: number | null = null;
  isExempted: boolean = false;
  membershipData: any = null;

  constructor(private downloadService: DownloadFileService, private snackBar: MatSnackBar, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.uploadForm?.get('businessIdentificationForm')?.get('country_of_business_registration')?.valueChanges.subscribe((value) => {
      this.filterCountry(value);
    });
    if (!this.country) {
      this.country = sessionStorage.getItem('selectedCountry');
    }
    this.extractLatestPaidStatus();
  }

  extractLatestPaidStatus(): void {
    this.membershipData = this.uploadForm?.value?.status;
    if (this.membershipData && Array.isArray(this.membershipData.status_history)) {
      // Filtering for PAID statuses that have a txn_id and amount property.
      const paidStatuses = this.membershipData.status_history.filter((item: any) => 
        item.status === 'PAID' && item.txn_id && item.amount !== undefined
      );
      if (paidStatuses.length) {
        // Sort descending by datetime to get the latest PAID status.
        paidStatuses.sort((a: any, b: any) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
        const latestPaid = paidStatuses[0];
        this.membershipFee = parseFloat(latestPaid.amount);
        // If the txn_id is "exempted" and amount is 0, marking as exempted.
        if (latestPaid.txn_id === 'exempted' && this.membershipFee === 0) {
          this.isExempted = true;
        }
      }
    }
  }


  filterCountry(name: string) {
    this.country = name;
  }


  getFileSize(fileSizeInBytes: any): string { 
    if (fileSizeInBytes < 1024) {
      return fileSizeInBytes + ' B'; // Display size in bytes
    } else if (fileSizeInBytes < 1024 * 1024) {
      return (fileSizeInBytes / 1024).toFixed(2) + ' KB'; // Display size in kilobytes
    } else {
      return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + ' MB'; // Display size in megabytes
    }
  }

  async downLoadFile(id: any) {
    this.snackBar.open('Please wait file is downloading', '', {
      duration: 3000,
    });
    const response = await this.downloadService.onFileDownload(id);
    if (response.status === 'success') {
      this.snackBar.open('File downloaded successfully', 'Close', {
        duration: 6000,
      });
    } else {
      this.toastr.error('Some error occured, could not download the file');
    }
  }

  getRemoveFilesValue(): boolean {
    const currentStatus = this.uploadForm?.value?.status?.current_status;
  
    // Check if current_status is 'CERTIFIED'
    if (currentStatus === 'CERTIFIED') {
      const statusHistory = this.uploadForm?.value?.status?.status_history;
      const certifiedEntry = statusHistory.find((entry: any) => entry.status === 'CERTIFIED' 
      && entry.datetime === this.uploadForm?.value?.status?.status_datetime);
  
      if (certifiedEntry) {
        return !!certifiedEntry.metadata?.remove_files;
      }
    } else if (currentStatus === 'REJECTED') {
      const statusHistory = this.uploadForm?.value?.status?.status_history;
      const rejectedEntry = statusHistory.find((entry: any) => entry.status === 'REJECTED');
  
      if (rejectedEntry) {
        return !!rejectedEntry.remove_files;
      }
    }
  
    // For statuses other than 'CERTIFIED', return false
    return false;
  }

}
