<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>
<div *ngIf="!isLoading">
  <form [formGroup]="certificationForm">
    <mat-stepper class="px-5" [linear]="isLinear" [disableRipple]="true" #stepper
      [orientation]="(stepperOrientation | async)!">
      <mat-step formGroupName="businessIdentificationForm" [stepControl]="
          certificationForm.get('businessIdentificationForm')!
        " [completed]="false">
        <ng-template matStepLabel class="text-primary">Business Identification</ng-template>
        <app-view-business-identification
          [businessIdentificationForm]="certificationForm"></app-view-business-identification>
      </mat-step>
  
      <mat-step formGroupName="businessProfileForm" [stepControl]="certificationForm.get('businessProfileForm')!"
        [completed]="false">
        <ng-template matStepLabel class="text-primary">Business Profile</ng-template>
        <app-view-business-profile [businessProfileForm]="certificationForm"></app-view-business-profile>
      </mat-step>
  
      <mat-step [stepControl]="certificationForm.get('ownershipForm')!" [completed]="false">
        <ng-template matStepLabel class="text-primary">Ownership, Management</ng-template>
        <app-view-ownership [files]="ownerFiles" [ownershipForm]="certificationForm" [viewing]="'admin'"></app-view-ownership>
      </mat-step>
  
      <mat-step [completed]="false">
        <ng-template matStepLabel class="text-primary">Documents</ng-template>
        <app-view-document [uploadForm]="certificationForm" [turnoverFiles]="turnoverFiles" [turnoverFiles]="turnoverFiles" [registrationFiles]="registrationFiles" [ownershipFiles]="ownershipFiles" [viewing]="'admin'"></app-view-document>
      </mat-step>
    </mat-stepper>
  </form>
</div>