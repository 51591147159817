import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { DbService } from '../../../../services/db/db.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-certification-admin',
  templateUrl: './view-certification-admin.component.html',
  styleUrl: './view-certification-admin.component.css'
})
export class ViewCertificationAdminComponent {
  isLoading = false;
  panelOpenState2 = false;
  embName!: string;
  isLinear = false;
  stepperOrientation!: Observable<StepperOrientation>;
  certificateForm!: FormGroup;
  status!: any;
  appId!: any;
  geographicalArray: any;
  ownerFiles: any[] = [];
  turnoverFiles: any[] = [];
  ownershipFiles: any[] = [];
  registrationFiles: any[] = [];

  certificationForm = this.fb.group({
    businessIdentificationForm: this.fb.group({
      facing_disadvantage: [{ value: '', disabled: true }],
      discrimination_challenges: [''],
      business_name: [{ value: '', disabled: true }],
      has_different_trading_name: [{ value: '', disabled: true }],
      different_trading_name: [{ value: '', disabled: true }],
      country_of_business_registration: [{ value: '', disabled: true }],
      business_registration_number: [{ value: '', disabled: true }],
      business_type: [''],
      vat_number: [{ value: '', disabled: true }],
      address_line1: [{ value: '', disabled: true }],
      address_line2: [{ value: '', disabled: true }],
      address_line3: [{ value: '', disabled: true }],
      postal_code: [{ value: '', disabled: true }],
      city: [{ value: '', disabled: true }],
      has_business_website: [{ value: '', disabled: true }],
      website_url: [{ value: '', disabled: true }],
      linkedin_handle: [{ value: '', disabled: true }],
      other_handles: [{ value: '', disabled: true }],
      how_did_you_hear_about_us: [{ value: '', disabled: true }],
    }),
    businessProfileForm: this.fb.group({
      business_description: [{ value: '', disabled: true }],
      company_foundation_date: [{ value: '', disabled: true }],
      number_of_employees: [{ value: '', disabled: true }],
      last_year_gross_turnover_euro: [{ value: '', disabled: true }],
      geographical_coverage: [],
      industry_sectors: [],
      service_offerings: [],
      largest_contract: [{ value: '', disabled: true }],
      key_clients: [{ value: '', disabled: true }],
      elevator_pitch: [{ value: '', disabled: true }],
    }),
    status: [],
    ownershipForm: this.fb.array([
      this.createOwner()
    ]),
    turnoverDocs: this.fb.array([]),
    ownershipDocs: this.fb.array([]),
    registrationDocs: this.fb.array([]),
    certificationDocs: this.fb.array([]),
    numberOfSubscriptionYear: [{ value: '', disabled: true }],
  });

  constructor(
    private fb: FormBuilder,
    private dbService: DbService,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 768px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.appId = params['id'];
        this.getCertificationData(this.appId);
      }
    });
  }

  async getCertificationData(id: any) {
    this.isLoading = true;
    try {
      const formData = await this.dbService.getCertificationForm(id);
      if (formData) {
        sessionStorage.setItem('selectedCountry', formData.businessIdentificationForm.country_of_business_registration);
        this.geographicalArray = formData.businessProfileForm.geographical_coverage;
        this.certificationForm.patchValue(formData);
        this.populateFileArray(formData);
        this.handleArrayData(formData.ownershipForm);
        this.populateFilesUploded(formData);
        this.isLoading = false;
      }
    } catch (error) {
      this.toastr.error('Some error occured while loading application', 'Error')
      console.error('Error loading existing data:', error);
      this.isLoading = false;
    }
  }

  private populateFilesUploded(formData: any) {
    const turnoverDocs = formData.turnoverDocs;
    turnoverDocs.forEach((item: any) => {
      if (item) {
        const fileObj = { file: item.file, is_upload_in_progress: false, upload_result: null, isDeleted: item?.file?.isDeleted,
          isUploaded: item.file?.name ? true : false }
       this.turnoverFiles.push(fileObj);
      }
    });
    const ownershipDocs = formData.ownershipDocs;
    ownershipDocs.forEach((item: any) => {
      if (item) {
        const fileObj = { file: item.file, is_upload_in_progress: false, upload_result: null, isDeleted: item?.file?.isDeleted,
          isUploaded: item.file?.name ? true : false }
       this.ownershipFiles.push(fileObj);
      }
    });
    const registrationDocs = formData.registrationDocs;
    registrationDocs?.forEach((item: any) => {
      if (item) {
        const fileObj = { file: item.file, is_upload_in_progress: false, upload_result: null, isDeleted: item?.file?.isDeleted,
          isUploaded: item.file?.name ? true : false }
       this.registrationFiles.push(fileObj);
      }
    });
    this.certificationForm.patchValue(formData);
    this.certificationForm = this.certificationForm;
  }

  populateFileArray(formData: any) {
    formData.turnoverDocs.forEach((turnoverDoc: any) => {
      const turnoverDocFormGroup = this.fb.group({
        file : {
          id: turnoverDoc.id,
          isDeleted: turnoverDoc.isDeleted,
          name: turnoverDoc.name,
          size: turnoverDoc.size,
        }
      });
      (this.certificationForm.get('turnoverDocs') as FormArray).push(turnoverDocFormGroup);
    });
    formData.ownershipDocs.forEach((ownershipDoc: any) => {
      const ownershipDocFormGroup = this.fb.group({
        file : {
          id: ownershipDoc.id,
          isDeleted: ownershipDoc.isDeleted,
          name: ownershipDoc.name,
          size: ownershipDoc.size,
        }
      });
      (this.certificationForm.get('ownershipDocs') as FormArray).push(ownershipDocFormGroup);
    });
    formData.registrationDocs?.forEach((registrationDoc: any) => {
      const registrationDocFormGroup = this.fb.group({
        file : {
          id: registrationDoc.id,
          isDeleted: registrationDoc.isDeleted,
          name: registrationDoc.name,
          size: registrationDoc.size,
        }
      });
      (this.certificationForm.get('registrationDocs') as FormArray).push(registrationDocFormGroup);
    });
  }

  private handleArrayData(value: any[]): void {
    const ownershipForm = this.certificationForm.get('ownershipForm') as FormArray;
    value?.forEach((item, index) => {
      if (index  < value.length-1) {
        ownershipForm.push(this.createOwner());
      }
      if (item.file) {
        this.pushFileInEdit(item.file);
      }
      const owner = ownershipForm.at(index) as FormGroup;
      if (owner) {
        owner.patchValue(item);
      }
    });
  }

  pushFileInEdit(file: any) {
    const fileObj = { file: file, is_upload_in_progress: false, upload_result: null, isDeleted: file?.isDeleted,
       isUploaded: file.name ? true : false }
    this.ownerFiles.push(fileObj);
  }

  createOwner(): FormGroup {
    return this.fb.group({
      // is_owner_minority: [{ value: '', disabled: true }],
      copy_details: [{ value: '', disabled: true }],
      business_owner_firstname: [{ value: '', disabled: true }],
      business_owner_lastname: [{ value: '', disabled: true }],
      email_address: [{ value: '', disabled: true }],
      job_title: [{ value: '', disabled: true }],
      percentage_ownership: [{ value: '', disabled: true }],
      ethnic_minority_group: [],
      // ethnic_minority_group: [{ value: '', disabled: true }],
      is_european_union_national: [{ value: '', disabled: true }],
      resident_status: [{ value: '', disabled: true }],
    });
  }
}
