import { Directive, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRegNumber]'
})
export class RegNumberDirective {
  @Input() country: string = '';

  constructor(@Optional() @Self() private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;
    let validCharacters: RegExp;

    // Determine allowed characters based on the country value
    if (this.country && this.country.toLowerCase() === 'de') {
      // Allow alphanumeric for Germany
      validCharacters = /^[a-zA-Z0-9]*$/;
    } else {
      // Otherwise, allow only numeric
      validCharacters = /^[0-9]*$/;
    }

    // Remove any invalid characters
    if (!validCharacters.test(inputValue)) {
      const pattern = (this.country && this.country.toLowerCase() === 'de')
        ? /[^a-zA-Z0-9]/g
        : /[^0-9]/g;
      inputValue = inputValue.replace(pattern, '');
    }

    // Convert to uppercase if alphanumeric is allowed (Germany)
    if (this.country && this.country.toLowerCase() === 'de') {
      inputValue = inputValue.toUpperCase();
    }

    // Update both the DOM element and the Angular form control
    inputElement.value = inputValue;
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(inputValue, { emitEvent: false });
    }
  }
}
